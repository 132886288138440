import { Button, FormLabel, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: "#f36060",
    //boxShadow: "0 6px 0 0 #d05252",

    "&:hover": {
      backgroundColor: "#d05252",
    },
    "&:active": {
      backgroundColor: "#d05252",
    },
  },
  yellow: {
    backgroundColor: "#e7ba1e",
    "&:hover": {
      backgroundColor: "#b89317",
    },
    "&:active": {
      backgroundColor: "#b89317",
    },
  },

  formLabelConfig: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "25",
    cursor: "pointer",
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign:'center',
  },
}));

function ButtonMenu({ Icon, children, href, buttonColor = "red" }) {
  const classes = useStyles();
  
  return (
    
    <Button
      fullWidth
      variant="contained"
      disableElevation
      className={classes[buttonColor]}
      href={href}
    >
      <FormLabel className={classes.formLabelConfig}>
        <div style={{ marginBottom: "6px", textAlign:'center', }}>
          <Icon style={{ fontSize: "6rem", }} />
        </div>
        {children}
      </FormLabel>
    </Button>
  );
}

export default ButtonMenu;
