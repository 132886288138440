import React, { useEffect, useState, useContext } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Grid,
} from "@material-ui/core";
import { Chart } from "primereact/chart";
import { BarChart, Home } from "@material-ui/icons";
import api from "../../../services/api";
import AuthContext from "../../../contexts/Auth/AuthContext";

export default function AlunosPorTarefaProjetos() {
  const { token } = useContext(AuthContext);

  const [chartDataTwo, setChartDataTwo] = useState({
    poloDigital: {
      bdcd: 0,
      dbn: 0,
      fcv: 0,
      fot: 0,
      fpi: 0,
      qs: 0,
      sis: 0,
      tcl: 0,
    },
    semi: {
      bdcd: 0,
      dbn: 0,
      fcv: 0,
      fpi: 0,
      qs: 0,
      sis: 0,
      tcl: 0,
    },
    flex: {
      bdcd: 0,
      dbn: 0,
      fcv: 0,
      fpi: 0,
      qs: 0,
      sis: 0,
      tcl: 0,
    },
  });

  const [lightOptionsTwo, setLightOptionsTwo] = useState({
    legend: {
      labels: {
        fontColor: "#495057",
      },
    },
    title: {
      display: true,
      text: "Alunos por tarefa - projetos",
      fontSize: 18,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  });

  useEffect(() => {
    api
      .get("/semi-polo/chart", { params: { token } })
      .then(({ data }) => {
        setChartDataTwo(data);
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <BottomNavigation
        showLabels
        style={{ marginBottom: 10, background: "#ddd" }}
      >
        <BottomNavigationAction
          label="Menu"
          icon={<Home />}
          style={{ color: "#b00f14" }}
          href="/"
        />
        <BottomNavigationAction
          label="Gráfico Geral"
          icon={<BarChart />}
          style={{ color: "#b00f14" }}
          href="/geral"
        />
      </BottomNavigation>

      <Container maxWidth="lg" style={{ background: "#fff" }}>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Chart
                  type="bar"
                  data={{
                    labels: [
                      "Questionário - Simulado",
                      "Dúvidas com tutores",
                      "Fórum Temático",
                      "Fórum - Café Virtual",
                      "Bloco de notas",
                      "Contribua com Documento",
                      "Fórum - Partilhando ideias",
                      "Curso Livre",
                    ],
                    datasets: [
                      {
                        label: "Semi-Presencial",
                        backgroundColor: "#42A5F555",
                        data: [
                          chartDataTwo.semi.qs,
                          chartDataTwo.semi.sis,
                          chartDataTwo.semi.fot,
                          chartDataTwo.semi.fcv,
                          chartDataTwo.semi.dbn,
                          chartDataTwo.semi.bdcd,
                          chartDataTwo.semi.fpi,
                          chartDataTwo.semi.tcl,
                        ],
                        hoverBackgroundColor: [
                          "#42A5F5",
                          "#42A5F5",
                          "#42A5F5",
                          "#42A5F5",
                          "#42A5F5",
                          "#42A5F5",
                          "#42A5F5",
                          "#42A5F5",
                        ],
                      },
                      {
                        label: "Polo Digital",
                        backgroundColor: "#FFA72655",
                        data: [
                          chartDataTwo.poloDigital.qs,
                          chartDataTwo.poloDigital.sis,
                          chartDataTwo.poloDigital.fot,
                          chartDataTwo.poloDigital.fcv,
                          chartDataTwo.poloDigital.dbn,
                          chartDataTwo.poloDigital.bdcd,
                          chartDataTwo.poloDigital.fpi,
                          chartDataTwo.poloDigital.tcl,
                        ],
                        hoverBackgroundColor: [
                          "#FFA726",
                          "#FFA726",
                          "#FFA726",
                          "#FFA726",
                          "#FFA726",
                          "#FFA726",
                          "#FFA726",
                          "#FFA726",
                        ],
                      },
                      {
                        label: "Flex",
                        backgroundColor: "#ADE2B555",
                        data: [
                          chartDataTwo.flex?.qs,
                          chartDataTwo.flex?.sis,
                          chartDataTwo.flex?.fot,
                          chartDataTwo.flex?.fcv,
                          chartDataTwo.flex?.dbn,
                          chartDataTwo.flex?.bdcd,
                          chartDataTwo.flex?.fpi,
                          chartDataTwo.flex?.tcl,
                        ],
                        hoverBackgroundColor: [
                          "#ADE2B5",
                          "#ADE2B5",
                          "#ADE2B5",
                          "#ADE2B5",
                          "#ADE2B5",
                          "#ADE2B5",
                          "#ADE2B5",
                          "#ADE2B5",
                        ],
                      },
                    ],
                  }}
                  options={lightOptionsTwo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
