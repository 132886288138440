import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import BarChartIcon from "@material-ui/icons/BarChart";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ButtonMenu from "../../components/ButtonMenu/ButtonMenu";
import Card from "@material-ui/core/Card";

import { CardHeader } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  container: {
    background: "#e5e4e4",
    display: "flex",
    height: "calc(100vh - 69px)",
    paddingBottom: "10px",
  },
  header: {
    padding: "10px 16px 0",
  },
  card: {
    marginTop: "20px",
  },
  titulo: {
    fontSize: "1.8rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.root}>
        <Card className={classes.card} variant="outlined">
          <CardHeader
            title={
              <Typography color="textSecondary" className={classes.titulo}>
                Gráficos
              </Typography>
            }
            className={classes.header}
          ></CardHeader>

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ButtonMenu
                  Icon={BarChartIcon}
                  buttonColor="red"
                  href="/projetos"
                >
                  Projetos
                </ButtonMenu>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ButtonMenu Icon={BarChartIcon} buttonColor="red" href="/geral">
                  Geral
                </ButtonMenu>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardHeader
            title={
              <Typography color="textSecondary" className={classes.titulo}>
                Rankings
              </Typography>
            }
            className={classes.header}
          ></CardHeader>

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ButtonMenu
                  Icon={EmojiEventsIcon}
                  buttonColor="yellow"
                  href="/ranking/digital/geral"
                >
                  Polo Digital
                </ButtonMenu>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ButtonMenu
                  Icon={EmojiEventsIcon}
                  buttonColor="yellow"
                  href="/ranking/semi/geral"
                >
                  Semipresencial
                </ButtonMenu>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ButtonMenu
                  Icon={EmojiEventsIcon}
                  buttonColor="yellow"
                  href="/ranking/ead100/geral"
                >
                  Ead 1º semestre
                </ButtonMenu>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ButtonMenu
                  Icon={EmojiEventsIcon}
                  buttonColor="yellow"
                  href="/ranking/esp-medicas/geral"
                >
                  Especialidades médicas
                </ButtonMenu>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}
