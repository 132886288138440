import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@material-ui/core";
import api from "../../../../services/api";
import AuthContext from "../../../../contexts/Auth/AuthContext";

import TableGeral from "../../components/TableGeral";

export default function SemiGeral() {
  const { token } = useContext(AuthContext);
  const [dados, setDados] = useState([]);

  useEffect(() => {
    api
      .post("ranking-semi", { token })
      .then(({ data }) => {
        const resultados = data.map((dados) => {
          return {
            ...dados,
            colocacao: (
              <Typography
                style={{
                  background:
                    dados.positionRanking === 1
                      ? "#f9bd01"
                      : dados.positionRanking === 2
                      ? "#c1c0ba"
                      : dados.positionRanking === 3
                      ? "#dc7c00"
                      : "transparent",
                  fontWeight: "bold",
                }}
              >
                {`${dados.positionRanking}º`}
              </Typography>
            ),
            points: (
              <Typography style={{ fontWeight: "bold" }}>
                {dados.totalPontos}
              </Typography>
            ),
          };
        });
        setDados(resultados);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <TableGeral results={dados} title="Semipresencial - Geral" type="semi" />
  );
}
