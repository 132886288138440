import React from "react";

export default React.createContext({
  isAuth: false,
  userName: "",
  token: "",
  loading: true,
  setUserName: function () {},
  setToken: function () {},
});
