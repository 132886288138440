import React from "react";
import { Card, Box, Typography } from "@material-ui/core";

export default function CardInteracao({ title, qtd, color }) {
  return (
    <Card style={{ background: color, padding: 5, height: "4rem" }}>
      <Box>
        <Typography
          style={{
            textAlign: "center",
            color: "#fff",
            fontSize: "12pt",
            fontWeight: "bold",
          }}
        >
          {title}: {title !== "Contribua com Documento" && <br />}
          {qtd}
        </Typography>
      </Box>
    </Card>
  );
}
