import React, { useEffect, useState, useContext } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Grid,
} from "@material-ui/core";
import { Home, BarChart } from "@material-ui/icons";
import { Chart } from "primereact/chart";
import api from "../../../services/api";
import AuthContext from "../../../contexts/Auth/AuthContext";

import CardInteracao from "./components/cardInteracao";

export default function AlunosPorTarefa() {
  const { token } = useContext(AuthContext);

  const [chartData, setChartData] = useState({
    qs: 0,
    sis: 0,
    fot: 0,
    fcv: 0,
    dbn: 0,
    bdcd: 0,
    fpi: 0,
    tcl: 0,
  });

  const [lightOptions, setLightOptions] = useState({
    legend: {
      display: false,
      labels: {
        fontColor: "#495057",
      },
    },
    title: { display: true, text: "Alunos por tarefa", fontSize: 18 },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  });

  useEffect(() => {
    api
      .get("/geral/chart", { params: { token } })
      .then(({ data }) => {
        setChartData({ ...chartData, ...data });
      })
      .catch((e) => console.log(e));
  }, [token]);

  return (
    <>
      <BottomNavigation
        showLabels
        style={{ marginBottom: 10, background: "#ddd" }}
      >
        <BottomNavigationAction
          label="Menu"
          icon={<Home />}
          style={{ color: "#b00f14" }}
          href="/"
        />
        <BottomNavigationAction
          label="Gráfico por Projetos"
          icon={<BarChart />}
          style={{ color: "#b00f14" }}
          href="/projetos"
        />
      </BottomNavigation>

      <Container maxWidth="lg" style={{ background: "#fff" }}>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Chart
              type="bar"
              data={{
                labels: [
                  "Questionário - Simulado",
                  "Dúvidas com tutores",
                  "Fórum Temático",
                  "Fórum - Café Virtual",
                  "Bloco de notas",
                  "Contribua com Documento",
                  "Fórum - Partilhando ideias",
                  "Curso Livre",
                ],
                datasets: [
                  {
                    backgroundColor: [
                      "#17A4C855",
                      "#114bb955",
                      "#d8510d55",
                      "#e1252555",
                      "#e1258d55",
                      "#5507ac55",
                      "#15a70c55",
                      "#d9d30755",
                    ],
                    data: [
                      chartData.qs,
                      chartData.sis,
                      chartData.fot,
                      chartData.fcv,
                      chartData.dbn,
                      chartData.bdcd,
                      chartData.fpi,
                      chartData.tcl,
                    ],
                    hoverBackgroundColor: [
                      "#17A4C8",
                      "#114bb9",
                      "#d8510d",
                      "#e12525",
                      "#e1258d",
                      "#5507ac",
                      "#15a70c",
                      "#d9d307",
                    ],
                  },
                ],
              }}
              options={lightOptions}
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Questionário Simulado"
              qtd={chartData.qs}
              color="#17A4C8"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Dúvidas com tutores"
              qtd={chartData.sis}
              color="#114bb9"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Fórum Temático"
              qtd={chartData.fot}
              color="#d8510d"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Café Virtual"
              qtd={chartData.fcv}
              color="#e12525"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Bloco de notas"
              qtd={chartData.dbn}
              color="#e1258d"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Contribua com Documento"
              qtd={chartData.bdcd}
              color="#5507ac"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Partilhando ideias"
              qtd={chartData.fpi}
              color="#15a70c"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ padding: 5 }}>
            <CardInteracao
              title="Curso Livre"
              qtd={chartData.tcl}
              color="#d9d307"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
