import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AuthContext from "../../contexts/Auth/AuthContext";
import { ExitToApp } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import showSnack from "../../utils/snacks";
import api from "../../services/api";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    background: "#b00f14",
    margin: 0,
    padding: 0,
    borderBottom: "5px solid #f9bd01",
  },
}));

export default function TopBar() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const myLocation = useLocation();
  const [titlePage, setTitlePage] = useState("");
  const { isAuth, userName, token } = useContext(AuthContext);

  const exitApp = () => {
    api
      .post("/auth/logout", {
        userName,
        token,
      })
      .then(({ data }) => {
        if (data.message) {
          sessionStorage.removeItem("@u@pig@aut@u@n");
          sessionStorage.removeItem("@u@pig@aut@t@k");
          showSnack(data.message, enqueueSnackbar);
          history.go(0);
        }
      })
      .catch(() => {
        showSnack("Erro de conexão com o servidor", enqueueSnackbar, "error");
      });
  };

  useEffect(() => {
    if (
      myLocation.pathname === "/projetos"
    ) {
      setTitlePage("FLASH BOARD - Painel de Indicadores por Projetos");
    }

    if (
      myLocation.pathname === "/geral"
    ) {
      setTitlePage("FLASH BOARD - Painel de tarefa por estudante");
    }

    if (
      myLocation.pathname === "/ranking/digital/geral" ||
      myLocation.pathname === "/ranking/digital/curso" ||
      myLocation.pathname === "/ranking/semi/geral" ||
      myLocation.pathname === "/ranking/semi/curso" ||
      myLocation.pathname === "/ranking/semi/polo"||
      myLocation.pathname === "/ranking/esp-medicas/geral"||
      myLocation.pathname === "/ranking/ead100/geral"||
      myLocation.pathname === "/ranking/esp-medicas/curso"||
      myLocation.pathname === "/ranking/ead100/curso"
    ) {
      setTitlePage("Ranking");
    }

    if (myLocation.pathname === "/") {
      setTitlePage("Indicadores");
    }

    if (!isAuth) {
      setTitlePage("");
    }
  }, [myLocation, isAuth]);

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <a href="/">
          <img src="/logo.png" style={{ width: "4rem" }} />
        </a>

        <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
          {titlePage}
        </Typography>

        {isAuth && (
          <IconButton
            onClick={() => {
              exitApp();
            }}
          >
            <ExitToApp style={{ color: "#fff" }} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}
