import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Router } from "react-router-dom";

import TopBar from "./components/TopBar";

import AlunosPorTarefa from "./pages/PainelIndicadores/AlunosPorTarefa";
import AlunosPorTarefaProjetos from "./pages/PainelIndicadores/AlunosPorTarefaProjetos";
import AuthContext from "./contexts/Auth/AuthContext";
import AuthProvider from "./contexts/Auth/AuthProvider";
import Sigin from "./pages/Login/Sigin";
import NotFound404 from "./pages/404";
import Menu from "./pages/Menu";

import DigitalGeral from "./pages/Ranking/PoloDigital/Geral";
import DigitalCurso from "./pages/Ranking/PoloDigital/Cursos";
import SemiGeral from "./pages/Ranking/Semipresencial/Geral";
import SemiCurso from "./pages/Ranking/Semipresencial/Cursos";
import SemiPolo from "./pages/Ranking/Semipresencial/Polo";
import Loading from "./components/Loading";

export default function Routes() {
  const { isAuth, loading } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <TopBar />
      {loading ? (
        <Switch>
          <Route path="/" exact component={Loading} />
        </Switch>
      ) : isAuth ? (
        <Switch>
          <Route path="/" exact component={Menu} />
          <Route path="/projetos" exact component={AlunosPorTarefaProjetos} />
          <Route path="/geral" exact component={AlunosPorTarefa} />
          <Route path="/ranking/digital/geral" exact component={DigitalGeral} />
          <Route path="/ranking/digital/curso" exact component={DigitalCurso} />
          <Route path="/ranking/semi/geral" exact component={SemiGeral} />
          <Route path="/ranking/semi/curso" exact component={SemiCurso} />
          <Route path="/ranking/semi/polo" exact component={SemiPolo} />

          <Route path="/ranking/ead100/geral" exact component={DigitalGeral} />
          <Route
            path="/ranking/esp-medicas/geral"
            exact
            component={DigitalGeral}
          />

          <Route
            path="/ranking/esp-medicas/curso"
            exact
            component={DigitalCurso}
          />
          <Route path="/ranking/ead100/curso" exact component={DigitalCurso} />

          <Route path="/ranking/ead100/polo" exact component={SemiPolo} />

          <Route path="/loading" exact component={Loading} />
          <Route path="*" component={NotFound404} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/" exact component={Sigin} />
          <Route path="*" component={NotFound404} />
        </Switch>
      )}
    </BrowserRouter>
  );
}
