import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";

import Topbar from "../../components/TopBar";
import Page404 from "../../assets/lottie/error-404-two.gif";

export default function PainelIndicadores() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 80,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                fontWeight: "bold",
                border: "1px solid #7ee7d3",
                padding: 10,
                color: "#5192f3",
                borderRadius: 4,
              }}
            >
              Página Não Encontrada !
            </Typography>
            <img src={Page404} style={{ height: "30rem", width: "30rem" }} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
