import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@material-ui/core";
import api from "../../../../services/api";
import AuthContext from "../../../../contexts/Auth/AuthContext";

import TableGeral from "../../components/TableGeral";
import { useLocation } from "react-router";

export function getProjeto(location) {
  return location().pathname.split("/")[2];
}
export default function DigitalGeral() {
  const { token } = useContext(AuthContext);
  const [dados, setDados] = useState([]);
  const projeto = getProjeto(useLocation);
  useEffect(() => {
    const routeGeral =
      projeto === "digital"
        ? "ranking-digi"
        : projeto === "ead100"
        ? "/ranking-novatos"
        : projeto === "esp-medicas"
        ? "/ranking-emedicas"
        : "";
    api
      .post(routeGeral, { token })
      .then(({ data }) => {
        const resultados = data.map((dados) => {
          return {
            ...dados,
            colocacao: (
              <Typography
                style={{
                  textAlign: "center",
                  background:
                    dados.positionRanking === 1
                      ? "#f9bd01"
                      : dados.positionRanking === 2
                      ? "#c1c0ba"
                      : dados.positionRanking === 3
                      ? "#dc7c00"
                      : "transparent",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                {`${dados.positionRanking}º`}
              </Typography>
            ),
            points: (
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                {dados.totalPontos}
              </Typography>
            ),
          };
        });
        setDados(resultados);
      })
      .catch((e) => console.log(e));
  }, []);
  if (projeto === "digital")
    return (
      <TableGeral results={dados} title="Polo Digital - Geral" type={projeto} />
    );
  else if (projeto === "ead100") {
    return (
      <TableGeral
        results={dados}
        title="EAD 100% - Primeiro Período - Geral"
        type={projeto}
      />
    );
  } else if (projeto === "esp-medicas") {
    return (
      <TableGeral
        results={dados}
        title="Especialidades Médicas - Geral"
        type={projeto}
      />
    );
  }
}
