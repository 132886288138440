import React, { useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import api from "../../services/api";

export default function AuthProvider({ children }) {
  const [userName, setUserName] = useState(
    sessionStorage.getItem("@u@pig@aut@u@n")
  );
  const [token, setToken] = useState(sessionStorage.getItem("@u@pig@aut@t@k"));
  const [isAuth, setisAuth] = useState(false);
  const [loading, setloading] = useState(true);

  const updateData = React.useCallback(() => {
    if (userName && userName !== sessionStorage.getItem("@u@pig@aut@u@n")) {
      sessionStorage.setItem("@u@pig@aut@u@n", userName);
    }
    if (token && token !== sessionStorage.getItem("@u@pig@aut@t@k")) {
      sessionStorage.setItem("@u@pig@aut@t@k", token);
    }
    if (userName && token) {
      api
        .post("/auth/valida", {
          userName,
          token,
        })
        .then(({ data }) => {
          setisAuth(data.authenticated);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setloading(false);
          console.clear();
        });
    } else {
      setloading(false);
    }
  }, [userName, token]);

  useEffect(() => {
    setloading(true);
    updateData();
  }, [userName, token]);
  useEffect(() => {
    if (userName && token) {
      api.post("/auth/valida", { userName, token }).then(({ data }) => {
        setisAuth(data.authenticated);
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuth, userName, setUserName, token, setToken, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
}
