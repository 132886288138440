import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BarChart, Home } from "@material-ui/icons";

export default function TableGeral({ results, title, type }) {
  return (
    <>
      {type === "digital"||type === "esp-medicas" ? (
        <BottomNavigation
          showLabels
          style={{ marginBottom: 10, background: "#ddd" }}
        >
          <BottomNavigationAction
            label="Menu"
            icon={<Home />}
            style={{ color: "#b00f14" }}
            href="/"
          />
          <BottomNavigationAction
            label="Curso"
            icon={<BarChart />}
            style={{ color: "#b00f14" }}
            href={`/ranking/${type}/curso`}
          />
        </BottomNavigation>
      ) : (
        <BottomNavigation
          showLabels
          style={{ marginBottom: 10, background: "#ddd" }}
        >
          <BottomNavigationAction
            label="Menu"
            icon={<Home />}
            style={{ color: "#b00f14" }}
            href="/"
          />
          <BottomNavigationAction
            label="Curso"
            icon={<BarChart />}
            style={{ color: "#b00f14" }}
            href={`/ranking/${type}/curso`}
          />
          <BottomNavigationAction
            label="Polo"
            icon={<BarChart />}
            style={{ color: "#b00f14" }}
            href={`/ranking/${type}/polo`}
          />
        </BottomNavigation>
      )}

      <Container>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Typography
              style={{
                padding: 10,
                color: "#b00f14",
                textAlign: "center",
                border: "1px solid #b00f14",
                borderRadius: 4,
                marginBottom: 10,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DataTable
              responsive
              value={results}
              style={{ textAlign: "center", fontWeight: "bold" }}
              paginator
              rows={10}
              emptyMessage="Sem registros"
            >
              <Column header="Colocação" field="colocacao" />
              <Column header="RA" field="RA" />
              <Column header="Polo" field="polo" />
              <Column header="Curso" field="curso" />
              <Column header="Pontos" field="points" />
            </DataTable>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
