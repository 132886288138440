import React, { useContext, useState } from "react";
import { Button, Container, Grid, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import "./styles.scss";
import api from "../../../services/api";
import AuthContext from "../../../contexts/Auth/AuthContext";
import showSnack from "../../../utils/snacks";
export default function Sigin() {
  const [state, setstate] = useState({ password: "", userName: "" });
  const { setToken, setUserName } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const valida = (e) => {
    api.post("/auth/login", { ...state }).then((res) => {
      if (res.data.token) {
        setToken(res.data.token);
        setUserName(state.userName);
      } else {
        showSnack("Usuário e/ou senha inválidos", enqueueSnackbar, "warning");
      }
    });
  };

  const changeText = (e) => {
    e.preventDefault();
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <Container className="sigin-container">
      <header>
        <img src="/static/images/AIAMIS.png" alt="Logo AIAMIS" />
        <h2>PAINEL DE INDICADORES DA GAMIFICAÇÃO</h2>
      </header>
      <main>
        <Grid xs={12} md={6}>
          <form>
            <TextField
              id="usuario"
              onChange={changeText}
              name="userName"
              type="text"
              fullWidth
              label="Usuario"
            />
            <TextField
              id="senha"
              onChange={changeText}
              type="password"
              name="password"
              fullWidth
              label="Senha"
            />
            <Button
              onClick={valida}
              variant="contained"
              fullWidth
              color="primary"
            >
              Entrar
            </Button>
          </form>
        </Grid>
      </main>
      <footer></footer>
    </Container>
  );
}
