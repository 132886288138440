import React from "react";
import { Container } from "@material-ui/core";

export default function Loading() {
  return (
    <Container
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ borderRadius: "50%", width: "50%" }}
        src="/static/images/loading.gif"
      />
    </Container>
  );
}
