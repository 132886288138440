import React from "react";
import { useLocation } from "react-router";

import TablePolo from "../../components/TablePolo";
import { getProjeto } from "../../PoloDigital/Geral";

export default function SemiPolo() {
  const projeto = getProjeto(useLocation);
  let title;
  if (projeto === "semi") {
    title = "Semipresencial - Polo";
  } else if (projeto === "ead100") {
    title = "EAD 100% - Primeiro Periodo - Polo";
  }
  return <TablePolo title={title} type={projeto} />;
}
