import React, { useEffect, useState, useContext } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../../services/api";
import { BarChart, Home } from "@material-ui/icons";
import AuthContext from "../../../contexts/Auth/AuthContext";

export default function TableCurso({ title, type }) {
  const { token } = useContext(AuthContext);
  const [cursos, setCursos] = useState([]);
  const [cursoSelecionado, setCursoSelecionado] = useState("");
  const [results, setResults] = useState();

  const mudar = (curso) => {
    if (curso) {
      if (type === "digital" || type === "esp-medicas") {
        const route =
          type === "digital" ? "ranking-digi-curso" : "/ranking-curso-emedicas";
        api
          .post(route, {
            curso,
            token,
          })
          .then(({ data }) => {
            const resultados = data.map((dados) => {
              return {
                ...dados,
                colocacao: (
                  <Typography
                    style={{
                      textAlign: "center",
                      background:
                        dados.positionRanking === 1
                          ? "#f9bd01"
                          : dados.positionRanking === 2
                          ? "#c1c0ba"
                          : dados.positionRanking === 3
                          ? "#dc7c00"
                          : "transparent",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    {`${dados.positionRanking}º`}
                  </Typography>
                ),
                points: (
                  <Typography
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    {dados.totalPontos}
                  </Typography>
                ),
              };
            });
            setResults(resultados);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (type === "semi" || type === "ead100") {
        const route =
          type === "semi" ? "ranking-curso-semi" : "/ranking-curso-novatos";
        api
          .post(route, {
            curso,
            token,
          })
          .then(({ data }) => {
            const resultados = data.map((dados) => {
              return {
                ...dados,
                colocacao: (
                  <Typography
                    style={{
                      textAlign: "center",
                      background:
                        dados.positionRanking === 1
                          ? "#f9bd01"
                          : dados.positionRanking === 2
                          ? "#c1c0ba"
                          : dados.positionRanking === 3
                          ? "#dc7c00"
                          : "transparent",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    {`${dados.positionRanking}º`}
                  </Typography>
                ),
                points: (
                  <Typography
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    {dados.totalPontos}
                  </Typography>
                ),
              };
            });
            setResults(resultados);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    let route;
    if (type === "digital") {
      route = "/digital/allcursos";
    } else if (type === "semi") {
      route = "/semi/allcursos";
    } else if (type === "esp-medicas") {
      route = "/emedicas/allcursos";
    } else if (type === "ead100") {
      route = "/novatos/allcursos";
    }
    api
      .get(route, { params: { token } })
      .then(({ data }) => {
        setCursos(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      {(type === "digital") | (type === "esp-medicas") ? (
        <BottomNavigation
          showLabels
          style={{ marginBottom: 10, background: "#ddd" }}
        >
          <BottomNavigationAction
            label="Menu"
            icon={<Home />}
            style={{ color: "#b00f14" }}
            href="/"
          />
          <BottomNavigationAction
            label="Geral"
            icon={<BarChart />}
            style={{ color: "#b00f14" }}
            href={`/ranking/${type}/geral`}
          />
        </BottomNavigation>
      ) : (
        <BottomNavigation
          showLabels
          style={{ marginBottom: 10, background: "#ddd" }}
        >
          <BottomNavigationAction
            label="Menu"
            icon={<Home />}
            style={{ color: "#b00f14" }}
            href="/"
          />
          <BottomNavigationAction
            label="Geral"
            icon={<BarChart />}
            style={{ color: "#b00f14" }}
            href={`/ranking/${type}/geral`}
          />
          <BottomNavigationAction
            label="Polo"
            icon={<BarChart />}
            style={{ color: "#b00f14" }}
            href={`/ranking/${type}/polo`}
          />
        </BottomNavigation>
      )}

      <Container>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Typography
              style={{
                padding: 10,
                color: "#b00f14",
                textAlign: "center",
                border: "1px solid #b00f14",
                borderRadius: 4,
                marginBottom: 10,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              {title}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "50%", marginBottom: 10 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Cursos
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={cursoSelecionado}
                onChange={(e) => {
                  setCursoSelecionado(e.target.value);
                  mudar(e.target.value);
                }}
                label="Cursos"
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>

                {cursos.map((dados) => {
                  return (
                    <MenuItem key={dados.Curso} value={dados.Curso}>
                      {dados.Curso}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {cursoSelecionado ? (
              <DataTable
                responsive
                value={results}
                style={{ textAlign: "center", fontWeight: "bold" }}
                paginator
                rows={10}
                emptyMessage="Sem registros"
              >
                <Column header="Colocação" field="colocacao" />
                <Column header="RA" field="RA" />
                <Column header="Polo" field="polo" />
                <Column header="Curso" field="curso" />
                <Column header="Pontos" field="points" />
              </DataTable>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 18,
                  border: "1px solid #b00f14",
                  borderRadius: 4,
                  padding: 10,
                }}
              >
                <font color="b00f14 ">Importante!</font> É preciso selecionar um
                curso para ser mostrado o ranking
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
