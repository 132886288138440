import React from "react";
import { useLocation } from "react-router";

import TableCurso from "../../components/TableCurso";
import { getProjeto } from "../Geral";

export default function DigitalCursos() {
  const projeto = getProjeto(useLocation);
  console.log(projeto);
  let title;
  if (projeto === "digital") {
    title = "Polo Digital - Curso";
  }
  if (projeto === "esp-medicas") {
    title = "Especialidades Médicas - Curso";
  }
  if (projeto === "ead100") {
    title = "EAD 100% - Primeiro Período - Curso";
  }
  return <TableCurso title={title} type={projeto} />;
}
