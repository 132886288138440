import React from "react";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Routes from "./routes";
import AuthProvider from "./contexts/Auth/AuthProvider";
import { ThemeProvider } from "@material-ui/core";
import theme from "./themes/theme";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
