import React, { useEffect, useState, useContext } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../../services/api";
import { BarChart, Home } from "@material-ui/icons";
import AuthContext from "../../../contexts/Auth/AuthContext";

export default function TablePolo({ title, type }) {
  const { token } = useContext(AuthContext);
  const [polos, setPolos] = useState([]);
  const [poloSelecionado, setPoloSelecionado] = useState("");
  const [results, setResults] = useState([]);

  const mudar = (polo) => {
    if (polo) {
      const route =
        type === "semi" ? "/ranking-polo-semi" : "/ranking-polo-novatos";
      api
        .post(route, { polo, token })
        .then(({ data }) => {
          const resultados = data.map((dados) => {
            return {
              ...dados,
              colocacao: (
                <Typography
                  style={{
                    textAlign: "center",
                    background:
                      dados.positionRanking <= 10 ? "#48ce41" : "transparent",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  {`${dados.positionRanking}º`}
                </Typography>
              ),
              points: (
                <Typography
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  {dados.totalPontos}
                </Typography>
              ),
            };
          });
          setResults(resultados);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    console.log(type);
    const route = type === "semi" ? "/semi/allpolos" : "/novatos/allpolos";
    api
      .get(route, { params: { token } })
      .then(({ data }) => {
        setPolos(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <BottomNavigation
        showLabels
        style={{ marginBottom: 10, background: "#ddd" }}
      >
        <BottomNavigationAction
          label="Menu"
          icon={<Home />}
          style={{ color: "#b00f14" }}
          href="/"
        />
        <BottomNavigationAction
          label="Geral"
          icon={<BarChart />}
          style={{ color: "#b00f14" }}
          href={`/ranking/${type}/geral`}
        />
        <BottomNavigationAction
          label="Curso"
          icon={<BarChart />}
          style={{ color: "#b00f14" }}
          href={`/ranking/${type}/curso`}
        />
      </BottomNavigation>

      <Container>
        <Grid container style={{ marginTop: 80 }}>
          <Grid item xs={12}>
            <Typography
              style={{
                padding: 10,
                color: "#b00f14",
                textAlign: "center",
                border: "1px solid #b00f14",
                borderRadius: 4,
                marginBottom: 10,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              {title}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl
              variant="outlined"
              style={{ width: "50%", marginBottom: 10 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Polos
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={poloSelecionado}
                onChange={(e) => {
                  setPoloSelecionado(e.target.value);
                  mudar(e.target.value);
                }}
                label="Cursos"
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>

                {polos.map((dados) => {
                  return (
                    <MenuItem key={dados.Polo} value={dados.Polo}>
                      {dados.Polo}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {poloSelecionado ? (
              <DataTable
                responsive
                value={results}
                style={{ textAlign: "center" }}
                emptyMessage="Sem dados"
              >
                <Column header="Colocação" field="colocacao" />
                <Column header="RA" field="RA" />
                <Column header="Polo" field="polo" />
                <Column header="Curso" field="curso" />
                <Column header="Pontos" field="points" />
              </DataTable>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 18,
                  border: "1px solid #b00f14",
                  borderRadius: 4,
                  padding: 10,
                }}
              >
                <font color="b00f14 ">Importante!</font> É preciso selecionar um
                polo para ser mostrado o ranking
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
