import axios from "axios";

// const URL = "http://localhost:3331/api/indicadores-game";
const URL = "https://indicadoresgame.aiamis.com.br/api/indicadores-game";

const api = axios.create({
  baseURL: URL,
});

export default api;
